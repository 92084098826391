import { createApp } from "vue";
import App from "./App.vue";
/*================== Bootstrap 5 & Bootstrap 5 Icons =================*/
import "./assets/bootstrap-lumen.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
/*================== GITART - Modals =================*/
import "gitart-vue-dialog/dist/style.css";
/*================== VUEX - Store =================*/
import store from "./store";

createApp(App).use(store).mount("#app");
