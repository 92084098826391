<template>
  <Navbar v-on:route="route" v-on:showNewTaskForm="show.newTaskForm = true" />
  <div class="container-fluid">
    <!-- <NewCardForm :settings="settings" /> -->
    <GDialog :model-value="show.newTaskForm" max-width="600" persistent>
      <NewCardForm
        :settings="settings"
        v-on:closeNewTaskForm="show.newTaskForm = false"
      />
    </GDialog>
    <Settings v-show="show.settings" />
    <BoardContainer v-show="show.board" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Settings from "./components/Settings";
import BoardContainer from "./components/BoardContainer";
import NewCardForm from "./components/NewCardForm";
import { GDialog } from "gitart-vue-dialog";
import "./assets/main.css";
import generating from "./services/generateId.js";

export default {
  name: "App",
  components: {
    BoardContainer,
    Settings,
    Navbar,
    GDialog,
    NewCardForm,
  },
  data() {
    return {
      // show: {
      //   settings: false,
      //   board: true,
      //   newTaskForm: false,
      // },
    };
  },
  methods: {
    route(destination) {
      switch (destination) {
        case "board":
          this.show.settings = false;
          this.show.board = true;
          break;
        case "settings":
          this.show.settings = true;
          this.show.board = false;
          break;
      }
    },
  },
  mounted: function () {
    console.log(generating());
  },
  computed: {
    settings: function () {
      console.log(this.$store.state.settings);
      return this.$store.state.settings;
    },
    show: function () {
      return this.$store.state.ui.show;
    },
  },
};
</script>
<style src="./assets/main.css"></style>
<style>
#app {
  width: 100%;
  height: 100%;
  background-color: white;
  font-family: Ubuntu !important;
}
</style>
