<template>
  <div class="container-fluid mb-3">
    <form @submit.prevent>
      <div class="row align-items-center d-flex">
        <div class="col">
          <h1 class="display-6">New Task</h1>
          <span class="plus"></span>
        </div>

        <div class="col align-items-center text-end">
          <i class="bi bi-x-lg text-danger h2"></i>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="input-group">
            <span class="input-group-text fw-bold bg-indigo">Task Name</span>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="What's this about?"
              required="true"
              v-model="newCard.name"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6">
          <!-- <label for="priority">Priority</label> -->
          <Multiselect
            v-model="newCard.priority"
            :options="settings.priorities"
            :mode="'single'"
            :searchable="true"
            :placeholder="'PRIORITY'"
          />
        </div>
        <div class="col-6">
          <!-- <label for="status">Status</label> -->
          <Multiselect
            v-model="newCard.status"
            :options="statusesForDropdown"
            :mode="'single'"
            :searchable="true"
            :placeholder="'STATUS'"
          />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6">
          <!-- <label for="startDate">Start date</label> -->
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text bg-secondary">Start</span>
            <input
              type="date"
              id="startDate"
              class="form-control form-control-sm"
              v-model="newCard.startDate"
            />
          </div>
        </div>

        <div class="col-6">
          <!-- <label for="startDate">Start date</label> -->
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text bg-secondary">End</span>
            <input
              type="date"
              id="endDate"
              class="form-control form-control-sm"
              v-model="newCard.endDate"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-2 text-end">
          <label for="labels"><i class="bi bi-tags h2 indigo"></i></label>
        </div>
        <div class="col-10">
          <Multiselect
            v-model="newCard.labels"
            :options="settings.labels"
            :mode="'tags'"
            :searchable="true"
            :placeholder="'Choose one or more labels'"
          />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <label for="description">Description</label>
          <textarea
            class="form-control"
            id="description"
            rows="3"
            placeholder="Describe this task"
            v-model="newCard.description"
          ></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text bg-secondary text-dark"
              ><i class="bi bi-list-check me-1"></i> Steps</span
            >
            <input
              type="text"
              class="form-control small"
              id="steps"
              placeholder="Add a new Step and press ENTER"
              v-model="staging.newStep"
              @keyup.enter.prevent="addStatus"
              @keydown.enter.prevent
            />
          </div>

          <ul class="mb-3 steps">
            <li v-for="step in newCard.steps" :key="step.name">
              <!--* EMPTY CIRCLE --->
              <span
                @click="step.done = true"
                v-show="!step.done"
                class="me-3 pointer"
                ><i class="bi bi-circle"></i
              ></span>
              <!--* CHECKED CIRCLE --->
              <span
                @click="step.done = false"
                v-show="step.done"
                class="me-3 pointer"
                ><i class="bi bi-check-circle text-success"></i
              ></span>
              <!--* <li> CONTENT with conditional class --->
              <span :class="{ completed: step.done }">{{ step.name }}</span>
              <!--* DELETE STEP --->
              <span @click="deleteStep(step.name)" class="ms-3 pointer small"
                ><i class="bi bi-x-lg text-danger"></i
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-end">
        <button class="btn btn-secondary me-3" @click="cancel">Cancel</button>
        <button class="btn btn-success" @click="submit">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";

export default {
  name: "NewCardForm",
  components: {
    Multiselect,
  },
  props: {},
  data() {
    return {
      newCard: {
        id: "",
        type: "",
        name: "",
        description: "",
        status: "",
        priority: "",
        upNext: "",
        startDate: "",
        endDate: "",
        labels: [],
        steps: [],
        attachments: [], // For future
      },
      staging: {
        newStep: null,
      },
    };
  },
  methods: {
    addStatus() {
      // Adding the new step to the newCard Array
      this.newCard.steps.push({
        name: this.staging.newStep,
        done: false,
      });
      this.staging.newStep = "";
    },
    deleteStep(name) {
      this.newCard.steps = this.newCard.steps.filter((el) => el.name !== name);
    },
    submit() {
      this.$store.commit("createTask", this.newCard);
      console.log("Submited --emitted");
      this.$store.commit("setData", {
        selector: "ui.show.newTaskForm",
        value: false,
      });
    },
    cancel() {
      this.$emit("closeNewTaskForm");
    },
  },
  mounted() {
    console.log(this.statuses);
  },
  computed: {
    settings: function () {
      return this.$store.state.settings;
    },
    statusesForDropdown: function () {
      const statuses = [];
      for (let column of this.settings.columns) {
        statuses.push({ label: column.name, value: column.name });
      }
      return statuses;
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped src="../assets/main.css"></style>
<style>
.container {
  max-width: 800px !important;
}
label {
  font-weight: bold;
  font-size: 0.8rem;
}
image.plus {
  content: url("../assets/plus.gif");
  height: 80px;
}
.completed {
  text-decoration: line-through;
  color: var(--success);
}
ul.steps {
  list-style-type: none;
}
.multiselect {
  padding: 0.1rem !important;
  font-size: 0.875rem !important;
  border-radius: 0.2rem !important;
}
.multiselect-tag {
  background-color: var(--indigo) !important;
  color: white;
}
.rem2 {
  font-size: 2rem;
}
</style>
