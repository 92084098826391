<template>
  <div class="col ms-1 me-1 p-0 shadow-sm bg-body rounded">
    <div class="kanban-head h5 text-center p-2" :class="column.class">
      {{ column.name }}
    </div>
    <div
      class="kanban-content p-2 pt-3"
      :class="{ dragover: isDragover }"
      @dragenter="onDragOver($event)"
      @dragleave="onDragLeave($event)"
      @dragover.prevent
      @drop="onDrop($event)"
    >
      <Card
        v-for="(card, index) in cards"
        :columnName="column.name"
        :key="index"
        :cardID="card.id"
        :card="card"
        draggable="true"
        @dragstart="startDrag($event, card)"
      />
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "./Card.vue";

export default {
  name: "Column",
  components: {
    Card,
  },
  props: {
    column: Object,
    cards: Array,
  },
  data() {
    return {
      isDragover: false,
    };
  },
  computed: {},
  mounted() {
    // console.log("Column Mounted");
    console.table(this.cards);
  },
  compBorder() {},
  methods: {
    onDrop(event) {
      const dropDetails = {
        movingCardID: event.dataTransfer.getData("itemID"),
        destinationColumnCode: this.column.name,
      };
      console.log(
        "Dropped card ID = ",
        dropDetails.movingCardID,
        "into Column ",
        dropDetails.destinationColumnCode
      );

      this.$store.commit("updateTask", dropDetails);
      this.isDragover = false;
    },
    onDragOver() {
      this.isDragover = true;
    },
    onDragLeave() {
      this.isDragover = false;
    },
    startDrag(event, item) {
      console.log("Start dragging ID = ", item.id);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.col {
  border-radius: 5px;
}
.kanban-content {
  min-height: 800px;
}
.dragover {
  /* border: 3px dashed var(--indigo); */
  background-color: #e0cffc;
}
</style>
