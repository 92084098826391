<template>
  <div>
    <div class="row mt-3">
      <Column
        v-for="(column, index) in columns"
        :key="index"
        :column="column"
        :cards="relatedCards(column.name)"
      />
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import Column from "./Column.vue";

export default {
  name: "Container",
  components: {
    Column,
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    relatedCards(columnName) {
      return this.cards.filter((card) => card.status === columnName);
    },
  },
  computed: {
    columns: function () {
      return this.$store.state.settings.columns;
    },
    cards: function () {
      return this.$store.state.tasks;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
