// Helper functions
export function preventDuplicates(columnName, allColumnsArray) {
  const test = allColumnsArray.filter((el) => el.name === columnName);
  if (test.length) {
    return true;
  }
}

export function reorderArray(arr, from, to) {
  console.log("Swap");

  let removed = arr.splice(from, 1);
  arr.splice(to, 0, removed[0]);
}

export function generateID(el) {
  return window.btoa(el + "_" + Date.now());
}
