<template>
  <nav class="navbar navbar-expand-md">
    <div class="container-fluid">
      <a class="navbar-brand text-second" href="#">{{
        $store.state.app.name
      }}</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample04"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link text-white" @click.prevent="route('board')"
              ><i class="bi bi-kanban"></i> Kanban</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="route('settings')"
              ><i class="bi bi-sliders"></i> Settings</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="showNewTaskForm"
              ><i class="bi bi-plus-circle"></i> New task</a
            >
          </li>
        </ul>
        <form>
          <input
            class="form-control form-control-sm"
            type="text"
            placeholder="Search"
          />
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    route(destination) {
      console.log("🧭 Routing to > ", destination);
      this.$emit("route", destination);
    },
    showNewTaskForm() {
      console.log("click");
      this.$emit("showNewTaskForm"); // Sending to parent to show the modal
    },
  },
  mounted() {},
};
</script>

<style>
a {
  cursor: pointer !important;
  color: white !important;
}
.navbar {
  background-color: var(--indigo);
  color: #00ff84 !important;
}
.text-second {
  color: #00ff84 !important;
  color: var(--teal-500) !important;
}
</style>
