import { createStore } from "vuex";
import { preventDuplicates, reorderArray, generateID } from "./helpers.js";

export default createStore({
  state: {
    app: {
      name: "Kantabs",
      version: "0.1",
    },
    ui: {
      show: {
        settings: false,
        board: true,
        newTaskForm: false,
      },
    },
    settings: {
      labels: ["cool", "yeah", "smack"],
      priorities: ["😹 Low", "🔺 Medium", "⚡ High", "🔥 Critical"],
      columns: [
        {
          name: "Backlog",
          id: "QmFja2xvZ18xNjQxODMxOTA4Njky",
          class: "bg-gray",
        },
        {
          name: "In Progress",
          id: "SW4gUHJvZ3Jlc3NfMTY0MTgzMTkyOTEwMg",
          class: "bg-blue",
        },

        {
          name: "On Hold",
          id: "T24gSG9sZF8xNjQxODMxOTcwMjky",
          class: "bg-yellow",
        },
        {
          name: "Blocked",
          id: "QmxvY2tlZF8xNjQxODMxOTgxOTY2",
          class: "bg-red",
        },
        { name: "Done", id: "RG9uZV8xNjQxODMxOTk2ODA2", class: "bg-green" },
      ],
      colors: [
        {
          id: "black",
          value: "#6610f2",
          bgClass: "bg-black",
          colorClass: "black",
        },
        {
          id: "blue",
          value: "#007bff",
          bgClass: "bg-blue",
          colorClass: "blue",
        },
        {
          id: "red",
          value: "#007bff",
          bgClass: "bg-red",
          colorClass: "red",
        },
        {
          id: "yellow",
          value: "#007bff",
          bgClass: "bg-yellow",
          colorClass: "yellow",
        },
        {
          id: "orange",
          value: "#007bff",
          bgClass: "bg-orange",
          colorClass: "orange",
        },
        {
          id: "teal",
          value: "#007bff",
          bgClass: "bg-teal",
          colorClass: "teal",
        },
        {
          id: "cyan",
          value: "#007bff",
          bgClass: "bg-cyan",
          colorClass: "cyan",
        },
        {
          id: "purple",
          value: "#007bff",
          bgClass: "bg-purple",
          colorClass: "purple",
        },
        {
          id: "gray",
          value: "#007bff",
          bgClass: "bg-gray",
          colorClass: "gray",
        },
        {
          id: "green",
          value: "#007bff",
          bgClass: "bg-green",
          colorClass: "green",
        },
      ],
    },
    tasks: [
      {
        id: "QmFja2xvZ18xNjQxODMxOTA4Njky",
        type: "task",
        name: "Buy Milk",
        description: "This is a long text description placeholder...",
        color: "text-secondary",
        status: "In Progress",
        priority: "😹 Low",
        labels: ["cool", "this is great", "awesome"],
      },
      {
        id: "ja2xvZ18xNjQxODMxOT",
        type: "task",
        name: "Order Pizza",
        description: "This is a long text description placeholder...",
        color: "text-primary",
        status: "Done",
        priority: "🔺 Medium",
      },
      {
        id: "18xNjQxODMxOTA4NjkMxOTy",
        type: "task",
        name: "Clean up home",
        description: "This is a long text description placeholder...",
        color: "text-success",
        status: "Backlog",
        priority: "🔥 Critical",
      },
    ],
  },
  mutations: {
    /**======================
     **   setData()
     *@param dataObj object {selector, value}
     *@return none
     *========================**/
    setData(state, dataObj) {
      if (state[dataObj.selector]) {
        state[dataObj.selector] = dataObj.value;
      }
    },
    createColumn(state, columnObj) {
      console.log("STORE");
      if (preventDuplicates(columnObj.name, state.settings.columns)) {
        console.log("Duplicate found, cannot create.");
      } else {
        state.settings.columns.push(columnObj);
      }
    },
    updateColumn(state, columnObj) {
      for (let col of state.settings.columns) {
        if (col.name === columnObj.name) {
          col = columnObj;
        }
      }
    },
    deleteColumn(state, columnObj) {
      console.log("STORE");
      state.settings.columns = state.settings.columns.filter(
        (el) => el.name !== columnObj.name
      );
    },
    createTask(state, taskObj) {
      console.log(taskObj);
      taskObj.id = generateID(taskObj.name); // Generating the Unique ID
      state.tasks.push(taskObj);
    },
    updateTask(state, dropDetails) {
      console.log("STORE");
      // Update dropped card NO SORTING
      console.log("🛒 ", dropDetails);
      for (const task of state.tasks) {
        if (task.id === dropDetails.movingCardID) {
          task.status = dropDetails.destinationColumnCode;
        }
      }
      ``;

      // SORTING
      if (dropDetails.receivingCardID) {
        const receivingCardIndex = state.tasks
          .map((el) => el.id)
          .indexOf(dropDetails.receivingCardID);
        const movingCardIndex = state.tasks
          .map((el) => el.id)
          .indexOf(dropDetails.movingCardID);

        reorderArray(state.tasks, movingCardIndex, receivingCardIndex);
      }
    },
    deleteTask() {},
  },
  actions: {},
  modules: {},
});
