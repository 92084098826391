<template>
  <div class="card">
    <div
      :class="{ 'card-landing-zone': true, dragover: isDragover }"
      @dragenter="onDragOver($event)"
      @dragleave="onDragLeave($event)"
      @dragover.prevent
      @drop="onDrop($event)"
    >
      DROP ME HERE
    </div>

    <div
      class="card-non-landing-zone shadow-sm p-1 mb-5 bg-body rounded"
      :class="border"
    >
      <p class="card-title fw-bolder">{{ card.name }}</p>
      <div class="card-body p-0 small">{{ this.card.description }}</div>
      <div class="row small mt-3">
        <div class="col">
          <span
            v-for="(label, index) in card.labels"
            :key="index"
            class="badge rounded-pill bg-indigo me-1"
            >{{ label }}</span
          >
        </div>
      </div>
      <div class="row small">
        <div class="col-6">{{ card.priority }}</div>
        <div class="col-6 text-end">#{{ card.id }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";

export default {
  name: "Card",
  props: {
    cardID: String,
    columnName: String,
    card: Object,
  },
  data() {
    return {
      isDragover: false,
    };
  },
  methods: {
    onDrop(event) {
      const dropDetails = {
        movingCardID: event.dataTransfer.getData("itemID"),
        destinationcolumnName: this.columnName,
        receivingCardID: this.card.id, // Specific to the card, not present in "Column.vue"
      };

      console.log(
        "Dropped card ID = ",
        dropDetails.movingCardID,
        "In Column",
        dropDetails.destinationcolumnName,
        "Over Card ",
        dropDetails.receivingCardID
      );

      this.$store.commit("updateTask", dropDetails);
      this.isDragover = false;
    },
    onDragOver() {
      this.isDragover = true;
    },
    onDragLeave() {
      this.isDragover = false;
    },
  },
  computed: {
    border() {
      const s = this.card.priority;
      console.log(s);

      if (/low/gim.test(s)) {
        return "border-low";
      }
      if (/medium/gim.test(s)) {
        return "border-medium";
      }
      if (/high/gim.test(s)) {
        return "border-high";
      }
      if (/critical/gim.test(s)) {
        return "border-critical";
      }
      return "border";
    },
  },
};
</script>

<style src="../assets/main.css"></style>
<style scoped>
.card {
  min-height: 50px;
  background-color: transparent !important;
  border: none;
}
.card-landing-zone {
  height: 25px !important;
  color: white;
  text-align: center;
  font-size: 0.8rem;
  border: none !important;
  background-color: transparent !important;
  /* border: 1px solid purple; */
}
.card-non-landing-zone {
  border: 1px solid var(--bs-gray-200);
  margin: 0px !important;
}
.dragover {
  border: 2px dashed var(--indigo) !important;
  color: var(--indigo);
}

/* PRIORITY CARD BORDER CLASSES */
.border-low {
  border-left: 4px solid var(--green) !important;
}
.border-medium {
  border-left: 4px solid var(--yellow) !important;
}
.border-high {
  border-left: 4px solid var(--orange) !important;
}
.border-critical {
  border-left: 4px solid var(--red) !important;
}
</style>
